import { postData } from '@utils'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    basketDrawer: false,
    basketDrawerTemporary: true,
    harici_sevkiyat_tarihleri:[],
    snackbar: {
      show: false,
      text: 'deneme',
      color: 'success',
    },
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    CHANGE_HARICI(state,value){
      state.harici_sevkiyat_tarihleri = value
    },
    TOGGLE_BASKET_DRAWER(state, value) {
      state.basketDrawer = value
    },
    TOGGLE_BASKET_DRAWER_TEMPORARY(state, value) {
      state.basketDrawerTemporary = value
    },
    TOGGLE_SNACKBAR(state, value) {
      state.snackbar.show = true
      state.snackbar.text = value.text
      state.snackbar.color = value.color
    },
  },
  actions: {
    fetchCustomersBySalDept(ctx) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getCustomersBySalDept',
          type: 'select',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchLogout() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'logout',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
