<template>
  <div>
    <div class="vertical-nav-header d-flex align-center justify-space-between px-2 py-4">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="isDark ? appLogoDark : appLogo"
          max-height="52px"
          max-width="52px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <v-img
            v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
            class="mt-3"
            max-width="90"
            :src="isDark ? textLogoDark : textLogo"
          ></v-img>
        </v-slide-x-transition>
      </router-link>

      <v-slide-x-transition>
        <div
          v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex align-center ms-1 mt-2"
          @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
        >
          <v-icon v-show="!menuIsVerticalNavMini" size="20" class="cursor-pointer">
            {{ icons.mdiRecordCircleOutline }}
          </v-icon>
          <v-icon v-show="menuIsVerticalNavMini" size="20" class="cursor-pointer">
            {{ icons.mdiRadioboxBlank }}
          </v-icon>
        </div>
        <v-icon v-else size="20" class="d-inline-block" @click.stop="$emit('close-nav-menu')">
          {{ icons.mdiClose }}
        </v-icon>
      </v-slide-x-transition>
    </div>
    <div v-if="false" class="nav-plasiyer-tally container px-6 mb-6 mt-3">
      <v-row class="mr-4" style="background-color: #201c30; border-radius: 18px">
        <div class="tally-left col-5">
          <div class="tally-left-inner">
            <div class="tally-card rating">
              <div class="tally-card-value">A</div>
              <div class="tally-card-text">Rayting</div>
            </div>
            <div class="tally-card order">
              <div class="tally-card-value">12</div>
              <div class="tally-card-text">Sıra</div>
            </div>
            <div class="tally-card score">
              <div class="tally-card-value">53</div>
              <div class="tally-card-text">Puan</div>
            </div>
          </div>
        </div>
        <div class="tally-right col-7 pl-0">
          <div class="tally-chart">
            <div class="tally-chart-inner">
              <div class="tally-chart-back">
                <div class="tally-chart-value">
                  <span>450</span>
                </div>
                <div class="ruler">
                  <div class="cm" v-for="i in 5" :key="i">
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                    <div class="mm"></div>
                  </div>
                </div>
              </div>
              <div class="tally-chart-text-1">
                <div class="text-1">3. Çeyrek</div>
                <div class="text-2">Satış Hedef ve Analiz</div>
              </div>
              <div class="tally-chart-text-2">758</div>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiRadioboxBlank, mdiRecordCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')
    const { isDark } = useAppConfig()

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      isDark,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-plasiyer-tally {
  .tally-card {
    background-image: radial-gradient(circle at 15% -3%, #433c62, #242137 116%);
    border-radius: 12px;
    padding: 9px 12px;
    text-align: center;
    &.order {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .tally-card-text {
      font-size: 9px;
      color: #7f7a98;
      font-weight: bold;
    }
    .tally-card-value {
      font-size: 24px;
      color: #b0abc8;
      font-weight: bold;
    }
  }
  .tally-chart {
    height: 100%;
    > div {
      height: 100%;
    }
    .tally-chart-inner {
      position: relative;
    }
    .tally-chart-back {
      width: 55px;
      height: 100%;
      background: #3b355a;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
    }
    .tally-chart-value {
      height: 90%;
      background: #0d6efd;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      span {
        text-align: right;
        padding-left: 13px;
        color: white;
        font-size: 13px;
      }
    }
    .tally-chart-text-1 {
      transform: translateX(60px) rotate(-90deg);
      transform-origin: 0 0;
      .text-1 {
        font-size: 24px;
        letter-spacing: 0;
        font-weight: bold;
        color: #635b8d;
        white-space: nowrap;
      }
      .text-2 {
        font-size: 13px;
        color: #635b8d;
        line-height: 1;
        white-space: nowrap;
      }
    }
    .tally-chart-text-2 {
      transform: translate(70px, -160px) rotate(-90deg);
      transform-origin: 0 0;
      text-align: right;
      font-size: 24px;
      font-weight: bold;
      color: #918ab7;
    }

    .ruler {
      position: relative;
      width: 520px;
      transform: rotate(90deg) translate(7px, -55px);
      transform-origin: 0 0;
      height: 10px;
    }
    .ruler .cm,
    .ruler .mm {
      position: absolute;
      border-left: 1px solid #b0abc8;
      height: 14px;
      width: 10%;
    }
    .ruler .cm:after {
      position: absolute;
      bottom: -15px;
      font: 11px/1 sans-serif;
    }
    .ruler .mm {
      height: 5px;
    }
    .ruler .mm:nth-of-type(5) {
      height: 10px;
    }
    .ruler .cm:nth-of-type(1) {
      left: 0%;
    }

    .ruler .cm:nth-of-type(2) {
      left: 10%;
    }

    .ruler .cm:nth-of-type(3) {
      left: 20%;
    }

    .ruler .cm:nth-of-type(4) {
      left: 30%;
    }

    .ruler .cm:nth-of-type(5) {
      left: 40%;
    }

    .ruler .cm:nth-of-type(6) {
      left: 50%;
    }

    .ruler .cm:nth-of-type(7) {
      left: 60%;
    }

    .ruler .cm:nth-of-type(8) {
      left: 70%;
    }

    .ruler .cm:nth-of-type(9) {
      left: 80%;
    }

    .ruler .cm:nth-of-type(10) {
      left: 90%;
    }

    .ruler .cm:nth-of-type(11) {
      left: 100%;
    }

    .ruler .mm:nth-of-type(1) {
      left: 10%;
    }
    .ruler .mm:nth-of-type(2) {
      left: 20%;
    }
    .ruler .mm:nth-of-type(3) {
      left: 30%;
    }
    .ruler .mm:nth-of-type(4) {
      left: 40%;
    }
    .ruler .mm:nth-of-type(5) {
      left: 50%;
    }
    .ruler .mm:nth-of-type(6) {
      left: 60%;
    }
    .ruler .mm:nth-of-type(7) {
      left: 70%;
    }
    .ruler .mm:nth-of-type(8) {
      left: 80%;
    }
    .ruler .mm:nth-of-type(9) {
      left: 90%;
    }
    .ruler .mm:nth-of-type(10) {
      left: 100%;
    }
    #mentionme {
      text-align: center;
      margin-top: 10%;
    }
  }
}
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)

.app-close,
.app-menu-mini {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
