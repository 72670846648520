<template>
  <v-sheet
    :height="$vuetify.breakpoint.xsOnly ? null : $vuetify.breakpoint.smOnly ? 42 : 56"
    :color="styleTheme ? 'grey' : 'transparent'"
    class="mb-3 mb-sm-0"
  >
    <v-autocomplete
      v-model="customer"
      placeholder="Müşteri Seçin"
      persistent-placeholder
      :items="customerItems"
      :loading="customerLoading"
      :search-input.sync="search"
      :background-color="$vuetify.breakpoint.xsOnly ? null : 'grey'"
      dark
      :label="$vuetify.breakpoint.xsOnly ? 'Müşteri' : null"
      clearable
      color="white"
      hide-details
      rounded
      item-text="name1"
      item-value="customer"
      class="musteriSecim"
      @change="customerChange"
    >
      <template v-slot:item="{ item }" class="ahmet">
        <v-list-item-content class="pb-0">
          <v-list-item-title class="white--text font-weight-bold">
            <div :class="!$vuetify.breakpoint.mdAndDown ? 'd-flex justify-space-between align-center' : ''">
              <div>
                <div>{{ item.name1 }}</div>
                <div v-if="item.customer !== 'all'" class="text-caption">
                  {{ item.address || 'Adres Bilgisi Yok' }}
                </div>
              </div>
              <div v-if="item.customer !== 'all'">
                <v-chip v-if="item.isactive == 1" color="success" label :x-small="$vuetify.breakpoint.mdAndDown">
                  Aktif
                </v-chip>
                <v-chip v-else color="warning" :x-small="$vuetify.breakpoint.mdAndDown" label> Pasif </v-chip>
              </div>
            </div>
          </v-list-item-title>
          <v-divider class="mt-3"></v-divider>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
  </v-sheet>
</template>

<script>
import { mdiDomain } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    styleTheme: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const customer = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const search = ref('')

    watch(search, val => {
      if (val === null || val?.length < 10) {
        customerLoading.value = true

        debouncedSearch()
      }
    })

    onMounted(() => {
      //  debouncedSearch()
    })
    const customerChange = (all = null) => {
      const data = {
        method: 'changeActiveCustomer',
        customer: all || customer.value,
      }

      store
        .dispatch('postMethod', data)
        .then(response => {
          if (response.error == 0) {
            store.commit('PLASIYER_CUSTOMER', response?.detail?.customer)
          } else if (response.error == 2) {
            store.commit('PLASIYER_CUSTOMER', customer.value)
          }
        })
        .catch(e => {
          customerLoading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
    }

    const debouncedSearch = useDebounceFn(() => {
      const data = {
        method: 'getCustomersBySalDept',
        type: 'select',
        q: search.value,
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          customerItems.value = response.detail
          customerItems.value.unshift({
            name1: 'Tüm Müşteriler',
            customer: 'all',
          })
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          customerLoading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
    }, 700)

    return {
      customerItems,
      search,
      customerLoading,
      customer,
      customerChange,
      icons: { mdiDomain },
    }
  },
}
</script>
<style lang="scss">
.musteriSecim {
  height: 100% !important;
  .v-input__control {
    height: 100% !important;
  }
  .v-input__slot.grey {
    height: 100% !important;
  }
}
.v-menu__content:not(.list-style) .v-list-item {
  height: auto !important;
}
</style>
