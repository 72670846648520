import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { fieldParams, formData, postData, querySearch } from '@utils'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tanimlar: {
      durum: [
        {
          text: 'Aktif',
          value: '1',
        },
        {
          text: 'Pasif',
          value: '0',
        },
      ],
      birim: [
        {
          text: 'Adet',
          value: 'AD',
        },
        {
          text: 'Cilt',
          value: 'CL',
        },
        {
          text: 'Santimetre',
          value: 'CM',
        },
        {
          text: 'Koli',
          value: 'K',
        },
        {
          text: 'Kilogram',
          value: 'KG',
        },
        {
          text: 'Paket',
          value: 'PK',
        },
      ],
      para: [
        {
          text: 'TL',
          value: 'TL',
        },
      ],
      kdv: [
        {
          text: '%18',
          value: '18.00',
        },
        {
          text: '%8',
          value: '8.00',
        },
        {
          text: '%1',
          value: '1.00',
        },
        {
          text: '%0',
          value: '0.00',
        },
      ],
      fabrika: [
        {
          text: 'Tekirdağ',
          value: '01',
        },
        {
          text: 'Konya',
          value: '02',
        },
      ],
      odemeDurum: [
        {
          text: 'Kredi Kartı',
          value: '1',
        },
        {
          text: 'Havale',
          value: '2',
        },
      ],
      siparisDurum: [
        {
          text: 'Bekliyor',
          value: '1',
          color: 'warning',
          outlined: false,
        },
        {
          text: 'Ödeme Bekleniyor',
          value: '9',
          color: 'warning',
          outlined: true,
        },
        {
          text: 'Onaylandı',
          value: '2',
          color: 'success',
          outlined: true,
        },
      
        {
          text: 'İptal Edildi',
          value: '4',
          color: 'error',
          outlined: false,
        },
        {
          text: 'Limit Yetersiz',
          value: '5',
          color: 'error',
          outlined: true,
        },
        {
          text: 'Kart Bilgileri Yanlış',
          value: '6',
          color: 'error',
          outlined: false,
        },
        {
          text: 'Havale Bekleniyor',
          value: '7',
          color: 'warning',
          outlined: true,
        },
        {
          text: 'İletişim Bilgileri Yanlış',
          value: '8',
          color: 'warning',
          outlined: true,
        },
        {
          text: 'Siparişiniz kargoya verildi',
          value: '10',
          color: 'success',
          outlined: true,
        },
        {
          text: 'Siparişiniz Hazır, Teslim Alabilirsiniz',
          value: '16',
          color: 'success',
          outlined: false,
        },
        {
          text: 'Sipariş teslim edildi',
          value: '11',
          color: 'success',
          outlined: false,
        },
        {
          text: 'İade Edildi',
          value: '12',
          color: 'error',
          outlined: false,
        },
        {
          text: 'İade için onay bekliyor',
          value: '13',
          color: 'warning',
          outlined: true,
        },
        {
          text: 'Red Edildi',
          value: '15',
          color: 'error',
          outlined: true,
        },
        {
          text: 'Kısmı Sevk',
          value: '97',
          color: 'warning',
          outlined: true,
        },
        {
          text: 'Aktarılamadı',
          value: '99',
          color: 'warning',
          outlined: true,
        },
      ],
    },
    plasiyerCustomer: null,
    paymentType: null,
  },
  mutations: {
    PLASIYER_CUSTOMER(state, value) {
      state.plasiyerCustomer = value
    },
    PAYMENT_TYPE(state, value) {
      state.paymentType = value
    },
  },
  actions: {
    postMethod(ctx, data) {
      return new Promise((resolve, reject) => {
        postData(data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadMethod(ctx, data) {
      return new Promise((resolve, reject) => {
        formData(data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMethod(ctx, { query, method }) {
      return new Promise((resolve, reject) => {
        postData(querySearch(query, method))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line object-curly-newline
    addUpdateMethod(ctx, { data, search, method, id = '' }) {
      return new Promise((resolve, reject) => {
        postData(fieldParams(data, search, method, id))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
