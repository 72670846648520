<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" :md="size ? 5 : 6">
        <Card
          :fields="fields"
          :labels="formData"
          :is-card-number-masked="isCardNumberMasked"
          :random-backgrounds="randomBackgrounds"
          :background-image="backgroundImage"
        />
      </v-col>
      <v-col cols="12" :md="size ? 7 : 6">
        <v-row :dense="!$vuetify.breakpoint.mdAndUp">
          <v-col cols="12">
            <v-text-field
              :id="fields.cardNumber"
              label="Kart Numarası"
              hide-details
              :rules="cardNumberRules"
              :value="formData.cardNumber"
              :maxlength="cardNumberMaxLength"
              data-card-field
              filled
              rounded
              class="rounded"
              @input="changeNumber"
              @focus="focusCardNumber"
              @blur="blurCardNumber"
            >
              <template v-slot:append>
                <v-btn icon plain tabindex="-1" class="mt-n1" @click="toggleMask">
                  <v-icon> {{ !isCardNumberMasked ? icons.mdiEye : icons.mdiEyeOff }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row :dense="!$vuetify.breakpoint.mdAndUp">
          <v-col cols="12">
            <v-text-field
              :id="fields.cardName"
              v-letter-only
              type="text"
              :rules="cardNameRules"
              label="Kart Üzerindeki İsim"
              :value="formData.cardName"
              data-card-field
              rounded
              class="rounded"
              filled
              hide-details=""
              @change="changeName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row :dense="!$vuetify.breakpoint.mdAndUp">
          <v-col cols="4">
            <v-select
              :id="fields.cardMonth"
              v-model="formData.cardMonth"
              data-card-field
              rounded
              class="rounded"
              filled
              :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
              :rules="[v => !!v || 'Item is required']"
              hide-details
              label="Ay"
              @change="changeMonth"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :id="fields.cardYear"
              v-model="formData.cardYear"
              data-card-field
              filled
              rounded
              class="rounded"
              :rules="[v => !!v || 'Item is required']"
              :items="cardYearItems"
              hide-details
              label="Yıl"
              @change="changeYear"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :id="fields.cardCvv"
              v-model="formData.cardCvv"
              v-number-only
              type="tel"
              rounded
              class="rounded"
              hide-details
              filled
              label="CVV"
              maxlength="4"
              :rules="cardNameCVV"
              data-card-field
              autocomplete="off"
              @change="changeCvv"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from '@/components/Card.vue'
import { mdiEye, mdiEyeOff } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiEyeOff,
        mdiEye,
      },
    }
  },
  name: 'CardForm',
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }

          return true
        }
        el.addEventListener('keypress', checkValue)
      },
    },
  },
  components: {
    Card,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        cardName: '',
        cardNumber: '',
        cardNumberNotMask: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      }),
    },
    size: {
      type: Boolean,
      default: true,
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv',
      },
      cardNumberRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 19) || 'Name must be less than 10 characters',
      ],
      cardNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 5) || 'Name must be less than 10 characters',
      ],
      cardNameCVV: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 3) || 'Name must be less than 10 characters',
      ],
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
    }
  },
  computed: {
    cardYearItems() {
      const items = []

      for (let index = 0; index < 10; index++) {
        items.push({
          text: new Date().getFullYear() + index,
          value: (new Date().getFullYear() + index).toString().slice(-2),
        })
      }

      return items
    },
    minCardMonth() {
      if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1

      return 1
    },
  },
  watch: {
    cardYear() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = ''
      }
    },
  },
  mounted() {
    this.maskCardNumber()
  },
  methods: {
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n
    },
    changeName(e) {
      this.formData.cardName = e
      this.$emit('input-card-name', this.formData.cardName)
    },
    changeNumber(e) {
      this.formData.cardNumber = e
      const value = this.formData.cardNumber.replace(/\D/g, '')

      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, '$1 ')
          .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
          .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        const lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1,
        )
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') {
          this.formData.cardNumber = this.formData.cardNumber.substring(0, this.formData.cardNumber.length - 1)
        }
      }
      this.$emit('input-card-number', this.formData.cardNumber)
    },
    changeMonth() {
      this.$emit('input-card-month', this.formData.cardMonth)
    },
    changeYear() {
      this.$emit('input-card-year', this.formData.cardYear)
    },
    changeCvv(e) {
      this.formData.cardCvv = e
      this.$emit('input-card-cvv', this.formData.cardCvv)
    },
    invaildCard() {
      const number = this.formData.cardNumberNotMask.replace(/ /g, '')
      let sum = 0
      for (let i = 0; i < number.length; i++) {
        let intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if (sum % 10 !== 0) {
        alert('invalidCardNumber')
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber
      this.mainCardNumber = this.formData.cardNumber
      const arr = this.formData.cardNumber.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.formData.cardNumber = arr.join('')
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber
    },
    focusCardNumber() {
      this.unMaskCardNumber()
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    },
  },
}
</script>
