<template>
  <v-dialog
  
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    width="320px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-sheet color="transparent" v-if="false">
        <v-list
          class="pa-0 rounded-pill"
          tile
          dense
          :width="$vuetify.breakpoint.mdAndDown ? 130 : 180"
          color="transparent"
        >
          <v-list-item
            v-bind="attrs"
            style="height: 100%"
            :class="$vuetify.breakpoint.mdAndDown ? '' : 'px-2'"
            v-on="on"
          >
            <v-list-item-avatar
              v-if="!$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? null : 'mr-1'"
            >
              <v-avatar>
                <v-icon size="24">
                  {{ icons.mdiCalendar }}
                </v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="pa-0">
              <v-list-item-title>Teslimat Tarihi</v-list-item-title>
              <v-list-item-subtitle>{{ date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      full-width
      :min="minDate"
      :max="maxDate"
      :show-current="false"
      :first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Vazgeç
      </v-btn>
      <v-btn
        depressed
        color="secondary"
        @click="$refs.dialog.save(date) || dateChange()"
      >
        Tamam
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

import { onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))

    const minDate = ref(null)
    const maxDate = ref(null)
    const date = ref(null)
    const modal = ref(false)
    onMounted(() => {
      date.value = store.state['app-order'].deliveryDate
      minDate.value = store.state['app-order'].deliveryMinDate
      maxDate.value = userData.role === 'PLSYR'
        ? store.state['app-order'].deliveryMaxDatePLSYR
        : store.state['app-order'].deliveryMaxDate
    })
    const dateChange = () => {
      store.commit('app-order/DELIVERY_DATE', date.value)
    }
    watch(
      () => store.getters['app-order/doneDelivery'],
      () => {
        date.value = store.state['app-order'].deliveryDate
      },
    )

    watch(
      () => store.state['app-order']?.deliveryMaxDate,
      () => {
        maxDate.value = store.state['app-order']?.deliveryMaxDate
      },
    )

    watch(
      () => store.state['app-order']?.deliveryMinDate,
      () => {
        minDate.value = store.state['app-order']?.deliveryMinDate
      },
    )

    return {
      date,
      modal,
      minDate,
      maxDate,
      dateChange,
      icons: { mdiCalendar },
    }
  },
}
</script>
