// eslint-disable-next-line object-curly-newline
import {
  mdiAccountSupervisor,
  mdiAccountTie,
  mdiBullhorn,
  mdiChefHat,
  mdiCog,
  mdiCollage,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiCrosshairs,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiFileLock,
  mdiFlash,
  mdiHomeOutline,
  mdiMapMarker,
  mdiMessageProcessing,
  mdiOrderNumericAscending,
  mdiShieldAccount,
  mdiTrayFull,
  mdiAsterisk,
  mdiAbacus,
  mdiCash,
} from '@mdi/js'

const accessToken = localStorage.getItem('accessToken')
export default [
  {
    title: 'Hata 404',
    icon: mdiHomeOutline,
    to: 'error-404',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Denetim Masası',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Gösterge Paneli',
    icon: mdiHomeOutline,
    to: 'dashboard-plasiyer',
    resource: 'dashboardPlasiyer',
    action: 'read',
  },
  {
    subheader: 'Hesaplar',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Yöneticiler',
    icon: mdiAccountTie,
    to: 'admin-list',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Kullanıcılar',
    icon: mdiAccountSupervisor,
    to: 'user-list',
    resource: 'Users',
    action: 'read',
  },
  {
    subheader: 'Sipariş',
    resource: 'KPanel',
    action: 'read',
  },
  {
    subheader: 'Ürün & Takip',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Ürünler',
    icon: mdiCollage,
    to: 'product-list',
    resource: 'ProductsMan',
    action: 'read',
  },
  {
    title: 'Sipariş Ver',
    icon: mdiChefHat,
    to: 'order-to',
    resource: 'Products',
    action: 'read',
  },
  {
    title: 'Kolay Sipariş Ver',
    icon: mdiFlash,
    to: 'order-easy',
    resource: 'Products',
    action: 'read',
  },

  {
    title: 'Sipariş Takip',
    icon: mdiOrderNumericAscending,
    to: 'order-tracking',
    resource: 'Sales',
    action: 'read',
  },
  {
    subheader: 'Ödeme & Tahsilat',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Tahsilat',
    icon: mdiCash,
    resource: 'collectionList',
    action: 'read',
    children: [
      {
        title: 'Çek Tahsilat',
        to: 'collection-add',
        resource: 'collectionForm',
        action: 'read',
      },
      {
        title: 'Tahsilatlar',
        to: 'collection-list',
        resource: 'collectionList',
        action: 'read',
      },
    ],
  },
  {
    title: 'Manuel Ödeme',
    icon: mdiContactlessPayment,
    to: 'payment',
    resource: 'Payment',
    action: 'read',
  },

  {
    title: 'Kart Hareketleri',
    icon: mdiCreditCard,
    to: 'credit-card-transactions',
    resource: 'PaymentActions',
    action: 'read',
  },
  // {
  //   subheader: 'Hesap',
  //   resource: 'KPanel',
  //   action: 'read',
  // },

  // {
  //   title: 'Kullanıcı Bilgilerim',
  //   icon: mdiShieldAccount,
  //   to: { name: 'user-view' },
  //   resource: 'KPanel',
  //   action: 'read',
  // },
  // {
  //   title: 'Adreslerim',
  //   icon: mdiMapMarker,
  //   to: { name: 'user-addresses' },
  //   resource: 'KPanel',
  //   action: 'read',
  // },

  // {
  //   title: 'Sözleşmelerim',
  //   icon: mdiFileLock,
  //   to: 'user-contracts',
  //   resource: 'KPanel',
  //   action: 'read',
  // },
  {
    subheader: 'Sistem',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Duyurular',
    icon: mdiBullhorn,
    to: 'event-list',
    resource: 'EventsMan',
    action: 'read',
  },

  {
    subheader: 'Raporlama',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Satış Belgeleri',
    icon: mdiFileDocument,
    resource: 'KPanel',
    action: 'read',
    children: [
      {
        title: 'Sipariş',
        to: { name: 'documents-list', params: { folder: 'order' } },
        resource: 'KPanel',
        action: 'read',
      },
      {
        title: 'İrsaliye',
        to: { name: 'documents-list', params: { folder: 'waybill' } },
        resource: 'KPanel',
        action: 'read',
      },
      {
        title: 'Fatura',
        to: { name: 'documents-list', params: { folder: 'bill' } },
        resource: 'KPanel',
        action: 'read',
      },
    ],
  },
  {
    title: 'Satış Analizi',
    icon: mdiAbacus,
    to: 'balance-list',
    resource: 'StockBalance',
    action: 'read',
  },
  {
    title: 'Cari Risk',
    icon: mdiAsterisk,
    to: 'risk-report',
    resource: 'StockBalance',
    action: 'read',
  },
  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extract-list',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Toplu SMS',
    icon: mdiMessageProcessing,
    to: 'sms-list',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Loglar',
    icon: mdiCrosshairs,
    to: 'logs',
    resource: 'Logs',
    action: 'read',
  },
  {
    title: 'Ayarlar',
    icon: mdiCog,
    to: 'settings-view',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Integrations',
    href: `${process.env.VUE_APP_URL}tr/integrations?token=${accessToken}`,
    icon: mdiFileDocumentOutline,
    resource: 'GeneralSettings',
    action: 'read',
  },
]
